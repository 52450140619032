import { Component, ContentChild, Input, Optional, TemplateRef, booleanAttribute } from '@angular/core';
import { ShellComponent } from '@app/shell/shell.component';
import { environment } from '@env/environment';
import { isTeamsWindow } from '@env/msal';
import { TgToolbarControlsDirective } from './tg-toolbar-controls.directive';
import { TgToolbarHeaderDirective } from './tg-toolbar-header.directive';
import { TgToolbarRightControlsDirective } from './tg-toolbar-right-controls.directive';

@Component({
  selector: 'tg-toolbar',
  templateUrl: './tg-toolbar.component.html',
  styleUrl: './tg-toolbar.component.scss',
})
export class TgToolbarComponent {
  constructor(@Optional() private shell: ShellComponent) {}
  @ContentChild(TgToolbarControlsDirective, { read: TemplateRef, static: true })
  controls: TemplateRef<any>;
  @ContentChild(TgToolbarRightControlsDirective, { read: TemplateRef, static: true })
  rightControls: TemplateRef<any>;
  @ContentChild(TgToolbarHeaderDirective, { read: TemplateRef, static: true })
  header: TemplateRef<any>;
  @Input('tbclass')
  tbclass: string;
  @Input({ transform: booleanAttribute })
  showFrill: boolean = true;
  @Input({ transform: booleanAttribute })
  showUser: boolean = true;
  @Input({ transform: booleanAttribute })
  showMenuToggle: boolean = true;
  @Input({ transform: booleanAttribute })
  hideTeams: boolean;
  get isTeamsAndHidden() {
    return (isTeamsWindow() || (!environment.production && window.teams_test)) && this.hideTeams;
  }
  createContext() {
    const toggleMenu = () => this.shell?.toggleNav();
    return {
      toggleMenu,
    };
  }
}
