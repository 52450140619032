import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FrillButtonModule } from '@app/components/frill-button/frill-button.module';
import { ShellMenuToggleModule } from '@app/components/shell-menu-toggle/shell-menu-toggle.module';
import { MaterialModule } from '@app/material.module';
import { UserMenuModule } from '@app/shared/user-menu/user-menu.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { TgToolbarContentDirective } from './tg-toolbar-content.directive';
import { TgToolbarControlsDirective } from './tg-toolbar-controls.directive';
import { TgToolbarHeaderDirective } from './tg-toolbar-header.directive';
import { TgToolbarRightControlsDirective } from './tg-toolbar-right-controls.directive';
import { TgToolbarComponent } from './tg-toolbar.component';

@NgModule({
  declarations: [
    TgToolbarComponent,
    TgToolbarControlsDirective,
    TgToolbarContentDirective,
    TgToolbarHeaderDirective,
    TgToolbarRightControlsDirective,
  ],
  imports: [CommonModule, MaterialModule, UserMenuModule, ShellMenuToggleModule, FrillButtonModule, FlexLayoutModule],
  exports: [
    TgToolbarComponent,
    TgToolbarHeaderDirective,
    TgToolbarContentDirective,
    TgToolbarControlsDirective,
    TgToolbarRightControlsDirective,
  ],
})
export class TgToolbarModule {}
