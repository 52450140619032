<button
  mat-icon-button
  (click)="openFrillFeedback()"
  class="mat-icon-button-flex"
  [disabled]="(giftLoading$ | async) || (giftError$ | async)"
  [matBadge]="giftBadgeCount$ | async"
  [matBadgeHidden]="!(giftBadgeCount$ | async)"
  matBadgeOverlap
  matBadgeColor="warn"
>
  @if (!(giftLoading$ | async)) {
    <mat-icon svgIcon="gift"></mat-icon>
  } @else {
    <mat-progress-spinner [strokeWidth]="2" [diameter]="18" mode="indeterminate"> </mat-progress-spinner>
  }
</button>
